<template>
  <div>
    <v-divider style="border-width: 1px;" />

    <project-tab-models
      v-if="backgroundModels"
      :background-models-prop="backgroundModels"
    />

    <div
      v-if="!backgroundModels || backgroundModels.length === 0"
      class="d-flex justify-center align-center tabs-content-empty-section"
    >
      <span> No Views has been added yet </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ProjectTabModels from '@/components/project/tab/ProjectTabModels.vue'

export default {
  name: "ProjectTabModelsContainer",
  components: { ProjectTabModels },
  computed: {
    ...mapGetters(
      {
        backgroundModels: 'project/getBackgroundModels'
      }
    )
  }
}
</script>

<style lang="scss" scoped>
.tabs-content-empty-section {
  min-height: 525px;
}

</style>
